import axios from "axios";
import { RESOLVE_SUPPORT_TICKET, REQUEST_SUPPORT_TICKET, ADD_NOTIFICATIONS, GET_BACKGROUND_CHECK, GET_COUNTRIES, GET_NOTIFICATIONS, GET_COMPANIES, GET_SUPPORT_TICKET, GENERATE_BGC_REPORT, GET_BACKGROUND_CHECK_BY_DATE } from "../@config/constant";
import { authHeader, handleResponse } from "../services/fack.backend";

export const fetchPendingRequestApi = ()  => {
    const requestOptions = {params: { status: 'pending', country_code: 'DO', limit: 100 }, headers: authHeader() };
    return axios.get(GET_BACKGROUND_CHECK, requestOptions)
}

export const fetchBackgroundCheckByDate = (start_date, end_date)  => {
    const requestOptions = {params: {start_date: start_date, end_date: end_date, country_code: 'DO', limit: 100 }, headers: authHeader() };
    return axios.get(GET_BACKGROUND_CHECK_BY_DATE, requestOptions)
}

export const fetchNotificationApi = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/notification.json`);
};


export const fetchCountryDataApi = () => {
    const requestOptions = {params: {}, headers: authHeader() };
    return axios.get(GET_COUNTRIES, requestOptions);
};


export const fetchCompanyDataApi = () => {
    const requestOptions = {params: {}, headers: authHeader() };
    return fetch(GET_COMPANIES, requestOptions).then(handleResponse)
    // return axios.get(GET_COMPANIES, requestOptions);
};


export const fetchNotificationDataApi = () => {
    const requestOptions = {params: {limit: 5}, headers: authHeader() };
    return axios.get(GET_NOTIFICATIONS, requestOptions);
};

// Notification
export const createNotificationApi = (data, params) => {
    const requestOptions = {params: {companies: params}, headers: {...authHeader(), 'Content-Type': 'application/json;charset=UTF-8'}};
    return axios.post(ADD_NOTIFICATIONS, data, requestOptions);
};

// Support ticket request
export const createSupportTicketApi = (data) => {
    const requestOptions = {params: {}, headers: {...authHeader(), 'Content-Type': 'application/json;charset=UTF-8'}};
    return axios.post(REQUEST_SUPPORT_TICKET, data, requestOptions);
};

// Fetch support ticket
export const fetchSupportTicketDataApi = (params) => {
    if(!params){
        params = {page: 1, limit: 20}
    }
    const requestOptions = {params: params, headers: authHeader() };
    return axios.get(GET_SUPPORT_TICKET, requestOptions)
}

// Support ticket resolve
export const createSupportTicketResolveApi = (data) => {
    const requestOptions = {params: {}, headers: {...authHeader(), 'Content-Type': 'application/json;charset=UTF-8'}};
    return axios.post(RESOLVE_SUPPORT_TICKET, data, requestOptions);
};

// Generate background check report
export const fetchBackgroundCheckReport = (national_id) => {
    return axios(`${GENERATE_BGC_REPORT}${national_id}`, {
        method: 'GET',
        responseType: 'blob',
        headers: authHeader(),
        params: {"include_sanction": false, "is_generate_file": true}
    })
}

// Fetch Generate BGC json by national id
export const fetchBackgroundCheckJson = (national_id) => {
    const requestOptions = {params: {'include_sanction': false, "is_generate_file": false}, headers: authHeader() };
    return axios.get(`${GENERATE_BGC_REPORT}${national_id}`, requestOptions)
}