import React from 'react';
import {Container,Row,Col,Form,FormGroup,Label,Input,Button} from 'reactstrap' 
import { NewPassword,RetypePassword,Done,EnterOTP,Resend, RememberPassword, SignIn,Send, RecoveryYourPassword, EnterYourEmail, PleaseCheckYourEmail, EmailInvalid, ResetPassword} from "../constant";
import { useState } from 'react';


import { showToaster } from '../@core/bootstrapNotify';
import { emailValidation } from '../@core/emailValidate';


const Forgetpwd = (props) => {

    const [showCreateNewPassword, setShowCreateNewPassword] = useState(false)
    const [email, setEmail] = useState("")
    const [togglePassword,setTogglePassword] = useState(false)
    const [password,setPassword] = useState("")

    
    const sendEmailGetToken = (e) => {

      // Email validation -> return
      if (!emailValidation(email)){
        showToaster('dangerToast', EmailInvalid)
        return 
      }

      // Send email settings
      // TODO: put forgot password logic here

      // After send email we notifier to user
      showToaster('successToast', PleaseCheckYourEmail)
      
      // Show Input new password
      setShowCreateNewPassword(true)
    }

    const handleChange = (e) => {
      setPassword(e.target.value)
    }
    const HideShowPassword  = (tPassword) => {
      setTogglePassword(!tPassword)
    }
    
    return (
      <Container fluid={true}>
      <Row>
          <Col xs="12">     
            <div className="login-card">
              <div>
                <div><a className="logo" href="#javascript"><img className="img-fluid for-light w-25" src={require("../assets/images/logo/overdo-logo.svg")} alt="looginpage"/>
                <img className="img-fluid for-dark w-25" src={require("../assets/images/logo/overdo-logo.svg")} alt="looginpage"/></a></div>
                <div className="login-main"> 
                  <Form className="theme-form">
                    <h3 className='text-center'>{ResetPassword}</h3>
                    <FormGroup>
                      <Label className="col-form-label">{EnterYourEmail}</Label>
                      <Row>
                        <Col md="12">
                          <Input className="form-control mb-1" type="email" value={email} placeholder='support@over.do' autoFocus
                            onChange={(e) => {setEmail(e.target.value)}}
                          />
                        </Col>
                        <Col xs="12">
                          <Button color="primary" className="btn-block m-t-10" type="button" onClick={sendEmailGetToken}>{Send}</Button>
                        </Col>
                      </Row>
                    </FormGroup>
                    <div className="text-center mt-4 mb-4"><span className="reset-password-link">{"If don't receive OTP?"}  
                    <a className="btn-link text-danger" href='#' onClick={sendEmailGetToken}>{Resend}</a></span></div>
                    
                    {!showCreateNewPassword ?'': 
                      <>
                        <FormGroup>
                        <Label className="col-form-label pt-0">{EnterOTP}</Label>
                        <Row>
                          <Col>
                            <Input className="form-control text-center opt-text" type="text" maxLength="10" placeholder='XXXXXX'/>
                          </Col>
                        </Row>
                        </FormGroup>
                          <h6 className="mt-4">{"Create Your Password"}</h6>
                          <FormGroup>
                            <Label className="col-form-label">{NewPassword}</Label>
                            <Input className="form-control" type={togglePassword ? "text" : "password" } name="login[password]" value={password} onChange={(e) => handleChange(e)} required="" placeholder="*********"/>
                            <div className="show-hide" onClick={() => HideShowPassword(togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                          </FormGroup>
                          <FormGroup className="mb-0">
                            <div className="checkbox ml-3">
                              <Input id="checkbox1" type="checkbox"/>
                              <Label className="text-muted" for="checkbox1">{RememberPassword}</Label>
                            </div>
                            <Button color="primary" className="btn-block" type="button">{Done}</Button>
                          </FormGroup>
                      </>
                    }
                    <p className="mt-4 mb-0">{"Already have an password?"}<a className="ml-2" href="/login">{SignIn}</a></p>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
}

export default Forgetpwd;