import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import {Container,Row,Col,Card,CardHeader,CardBody} from 'reactstrap'
import { convertUTCDateToLocalDate } from '../../@core/convertDateTimeToLocal';
import { fetchSupportTicketDataApi } from '../../api';
// import { fetchNotificationDataApi, fetchSupportTicketDataApi } from '../../api';
import {NewSupportTicket} from '../../constant'
import GenerateSupportTicketForm from './form/generateSupportTicketForm';
import SupportTicketList from './list/supportTicketList';

const GenerateSupportTicket = (props) => {
  const isSupperUser = localStorage.getItem('isSupperUser')?true:false
  return (
    <Fragment>
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update pt-5">
          <Col sm="12 pt-10">
            <Card>
              <CardHeader>
                
                {
                  !isSupperUser?<h5>{NewSupportTicket}</h5>:<h5>{"Support Ticket List"}</h5>
                } 
              </CardHeader>
              <CardBody>
              {
                  !isSupperUser?<GenerateSupportTicketForm />:<SupportTicketList/>
              }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GenerateSupportTicket;