import React, { Fragment, useEffect } from 'react';
import ScrollArea from 'react-scrollbar';
import { useState } from 'react';
import { Container, Row, Col, Card, CardBody, InputGroup, Input, InputGroupAddon, Button, Toast } from 'reactstrap'
import { Search } from '../../constant'
import { fetchBackgroundCheckJson, fetchBackgroundCheckReport } from '../../api';
import { showToaster } from '../../@core/bootstrapNotify';


const bgIpAfterSearch = {
  background: "#A2D4DF",
  borderRadius: "5px"
}
const myScrollbar = {
  border: "10px solid #DBDBDB"
}


const GenerateBackgroundCheckReport = (props) => {

  const [nationalId, setNationalId] = useState()
  const [background, setBackground] = useState(null)
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [dataBGC, setDataBGC] = useState(null)

  const handleSearch = date => {
    // Search
    if(!nationalId){
      showToaster('dangerToast', "Please check your nationalID");
      return;
    }

    setBackground(bgIpAfterSearch)
    
    // Show json
    setDataBGC([])
    setShow2(false)
    fetchBackgroundCheckJson(nationalId).then(res => {
      setDataBGC(res.data)
      setShow2(true)
    }).catch((err) => {
      setShow2(true)
      console.log(err)
    });

    // Show PDF
    setShow(false)
    fetchBackgroundCheckReport(nationalId).then(response => {
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'}
      );
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      const iframe = document.querySelector("iframe");
      if (iframe?.src) iframe.src = fileURL;
      setShow(true)
    })
    .catch((err) => {
      setShow(true)
      console.log(err)
    });
    
  };

  useEffect(() => {
    
  }, []);

  return (
    <Fragment>
      
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update pt-5">
        <div id="response"></div>
          <Col sm="12" xl="12" lg="12">
            <Card className="o-hidden">
              <CardBody className="b-r-4 card-body">
                <InputGroup className="text-box border-radius-5" >
                  <Input
                    type="text"
                    className="form-control input-txt-bx text-center"
                    placeholder="Input national id ..."
                    value={nationalId}
                    style={background}
                    onChange={(e)=>{setNationalId(e.target.value)}}
                  />
                  <InputGroupAddon addonType="append">
                    <Button color="primary btn-pill" className="bootstrap-touchspin-up" onClick={handleSearch}>{Search}</Button>
                  </InputGroupAddon>
                </InputGroup>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" xl="12">
            <Card>
              <CardBody className='vh-100'>
                {
                  !background ?
                    <p className='text-center'><strong>Input</strong> national ID and click <strong>Generate</strong> button</p>
                  : (
                    <Row className="second-chart-list third-news-update">
                    <Col sm="6" xl="6" lg="6" className='ml-0 pl-0 mt-0 pt-0'>
                      <Card className="o-hidden">
                        <ScrollArea
                          style={{ height: 600, maxHeight: 600}}
                          horizontal={false}
                          vertical={true}
                        >
                          <div style={myScrollbar}>
                          {
                              !show2?<>
                              <Col>
                                <div className="loader-box">
                                  <div className="loader-39"></div>
                                </div>
                              </Col>
                              </>:""}
                            <p>
                              {
                                dataBGC ? <pre><code  style={{display: !show2?'none':''}} class="language-javascript">{JSON.stringify(dataBGC, null, 2)}</code></pre>:''
                              }
                            </p>
                          </div>
                        </ScrollArea>
                      </Card>
                    </Col>
                    <Col sm="6" xl="6" lg="6" className='mr-0 pr-0 mt-0 pt-0'>
                      <Card className="o-hidden">
                        <ScrollArea
                          style={{ height: 600, maxHeight: 600}}
                          horizontal={false}
                          vertical={true}
                        >
                          <div style={{height: 700}} className='text-center'>
                            {
                              !show?<>
                              <Col>
                                <div className="loader-box">
                                  <div className="loader-39"></div>
                                </div>
                              </Col>
                              </>:""}
                              <iframe src="" width="100%" height="100%" style={{display: !show?'none':''}}></iframe>
                          </div>
                        </ScrollArea>
                      </Card>
                    </Col>
                  </Row>
                  )
                }

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
    
  );
}

export default GenerateBackgroundCheckReport;