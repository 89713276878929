import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, ListGroup, ListGroupItem, Label, Tooltip, Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import {Pending, Cancel, Success, Passed, WithBadges, Date, NoPendingRequestFound, PopoverTitle} from '../../constant'
import { Check, CheckCircle, Pause, StopCircle } from 'react-feather';
import DatePicker from "react-datepicker";
import { fetchBackgroundCheckByDate } from '../../api';

const BackgroundCheckByDate = (props) => {
  const [show, setShow] = useState(true);
  const [popover, setPopover] = useState(false)
  const OffsetToggle = () => setPopover(!popover);
  const [startDate, setsSartDate] = useState(null)
  const [endDate, setsEndDate] = useState(null)
  const [backgroundCheckData, setBackgroundCheckData] = useState([])

  const handleChangeStartDate = date => {
    setsSartDate(date)
  }

  const handleChangeEndDate = date => {
    setsEndDate(date)
  }

  const handleSearch = search => {
    setShow(false)
    fetchBackgroundCheckByDate(startDate, endDate).then(response => {
      setBackgroundCheckData(response.data)
      setShow(true)
    })
    .catch((err) => {
      setShow(true)
      console.log("error")
    });
    
  };

  return (
    <Fragment>
      <Container fluid={true}>
      <Row className="second-chart-list third-news-update pt-5">
          <Col sm="12" xl="12">
            <Card>
              <CardBody>
                <ListGroup>
                  <Row className="">
                    <Col md="5 mb-3">
                      <Label>{"Date From"}</Label>
                      <DatePicker className="form-control digits" selected={startDate}  dateFormat="yyyy/MM/dd"  onChange={handleChangeStartDate} />
                    </Col>
                    <Col md="5 mb-3">
                      <Label>{"Date To"}</Label>
                      <DatePicker className="form-control digits" selected={endDate}  dateFormat="yyyy/MM/dd"  onChange={handleChangeEndDate} />
                    </Col>
                    <Col md="2">
                      <button style={{marginTop: "1.8rem"}} class="btn-square btn btn-secondary align-middle"  onClick={handleSearch}>{"Search"}</button>
                    </Col>
                  </Row>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="second-chart-list third-news-update pt-5">
          <Col sm="12" xl="12">
            <Card>
              <CardBody>
                <ListGroup>
                  <Col sm="12" xl="12" lg="12">
                  {
                    !show?<>
                    <Col>
                      <div className="loader-box">
                        <div className="loader-39"></div>
                      </div>
                    </Col>
                    </>:""}
                  {backgroundCheckData.length > 0 ?
                    backgroundCheckData.map((bgc, index) => {
                    return (
                      <Card className="o-hidden" key={index} style={{display: !show?'none':''}}>
                        <CardBody className="p-3">
                          <div className="media static-top-widget">
                            <div className="align-self-right text-right"></div>
                            <div className="media-body">
                              <span className="m-0">{`${index + 1}. ${bgc.person.first_name} ${bgc.person.middle_name} ${bgc.person.last_name}`} / #{bgc.id}</span>
                            </div>
                            <span className={`badge ${bgc.status === 'pending'?"badge-warning": bgc.status === 'failed'?"badge-secondary": "badge-success"} digits`}>{bgc.status}</span>
                          </div>
                        </CardBody>
                      </Card>
                    )
                    })
                    : 
                    <Card className="o-hidden">
                      <CardBody className="p-3">
                        <div className="media static-top-widget">
                          <div className="align-self-right text-right"></div>
                          <div className="media-body">
                            <span className="m-0">{NoPendingRequestFound}</span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  }
                  </Col>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default BackgroundCheckByDate;