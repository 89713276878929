import Default from '../components/dashboard/default'
import PendingRequest from '../components/dashboard/pedingRequest'
import BackgroundCheckByDate from '../components/dashboard/backgroundCheckByDate'
import GenerateBackgroundCheckReport from '../components/dashboard/generateBackgroundCheckReport'
import GenerateSupportTicket from '../components/dashboard/generateSupportTicket'
import UserProfile from '../components/profile/userProfile'


export const routes = [
        { path:"/dashboard/default/:layout/", Component: Default},
        { path:"/pending-request/:layout/", Component: PendingRequest},
        { path:"/bgc-by-date/:layout/", Component: BackgroundCheckByDate},
        { path:"/generate-bgc-report/:layout/", Component: GenerateBackgroundCheckReport},
        { path:"/generate-support-ticket/:layout/", Component: GenerateSupportTicket},
        { path:"/app/users/userProfile/:layout/", Component: UserProfile},
]