const env = process.env.REACT_APP_ENV;
let BASE_URL = process.env.REACT_APP_BASE_URL_DEV
if(env === 'stag'){
    BASE_URL = process.env.REACT_APP_BASE_URL_STAG
}else if (env === 'prod'){
    BASE_URL = process.env.REACT_APP_BASE_URL_PROD
}

export const POST_LOGIN = `${BASE_URL}/api/v1/login/access-token`
export const GET_USERS_ME = `${BASE_URL}/api/v1/users/me`
export const GET_USERS_ME_FULL = `${BASE_URL}/api/v1/users/me/full`
export const GET_COUNTRIES = `${BASE_URL}/api/v1/countries/`

// Background check
export const GET_BACKGROUND_CHECK = `${BASE_URL}/api/v1/background-checks/beta`
export const GET_BACKGROUND_CHECK_BY_DATE = `${BASE_URL}/api/v1/background-checks/by_date`

// NOTIFICATION
export const GET_NOTIFICATIONS = `${BASE_URL}/api/v1/notifications/`
export const ADD_NOTIFICATIONS = `${BASE_URL}/api/v1/notifications/`

// COMPANY
export const GET_COMPANIES = `${BASE_URL}/api/v1/companies/`

// REQUEST_SUPPORT_TICKET
export const REQUEST_SUPPORT_TICKET = `${BASE_URL}/api/v1/support-tickets/`
export const GET_SUPPORT_TICKET = `${BASE_URL}/api/v1/support-tickets/`
export const RESOLVE_SUPPORT_TICKET = `${BASE_URL}/api/v1/support-tickets/resolve-ticket`

// Generate BGC Report
export const GENERATE_BGC_REPORT = `${BASE_URL}/api/v1/reports/do/`
export const GENERATE_BGC_JSON = `${BASE_URL}/api/v1/background-checks/do`