import React,{useState,useEffect} from 'react';
import man from '../assets/images/dashboard/profile.jpg';
import {Container,Row,Col,Form,FormGroup,Input,Label,Button,TabContent,TabPane} from 'reactstrap'
import {firebase_app,googleProvider,facebookProvider,githubProvider, Jwt_token } from '../data/config'
import { useAuth0 } from '@auth0/auth0-react'
import { toast } from 'react-toastify';
import {withRouter} from 'react-router-dom'
import {Password,SignIn,RememberPassword,ForgotPassword ,AUTH0, LoginWithJWT, UserName } from '../constant';
import { POST_LOGIN, GET_USERS_ME } from '../@config/constant';
import { showToaster } from '../@core/bootstrapNotify';
import { authHeader, handleResponse } from '../services/fack.backend';

var axios = require('axios');
var qs = require('qs');

const Logins = (props) => {
  
    const {loginWithRedirect} = useAuth0()
    const [username, setUsername] = useState("user-x");
    const [password, setPassword] = useState("password-for-use-x");
    const [loading,setLoading] = useState(false) 
    const [selected, setSelected] = useState("jwt");
    const [togglePassword,setTogglePassword] = useState(false)

    const [value, setValue] = useState(
        localStorage.getItem('profileURL' || man)
    );
    const [name, setName] = useState(
        // localStorage.getItem('Name')
    );

    useEffect(() => {
      
    localStorage.setItem('profileURL', value);
    // localStorage.setItem('Name', name);
    }, [value,name]);

    const loginAuth = async (e) => {
      e.preventDefault();
      setLoading(true)

      try {
          await firebase_app.auth().signInWithEmailAndPassword(username, password).then(function () {
                setValue(man);
                setName("Emay Walter");
                localStorage.setItem('token', Jwt_token);
                setTimeout(() => {
                  props.history.push(`${process.env.PUBLIC_URL}/dashboard/default/`);
                }, 200);
                })
      } catch (error) {
          setTimeout(() => {
              toast.error("Oppss.. The password is invalid or the user does not have a password.");
          }, 200);
      }
    }

    const googleAuth = async () => {
      
      try {
              firebase_app.auth().signInWithPopup(googleProvider).then(function (result) {
              setName(result.user.displayName);
              setValue(result.user.photoURL)
              localStorage.setItem('token', Jwt_token);
              setTimeout(() => {
                props.history.push(`${process.env.PUBLIC_URL}/dashboard/default/`);
              }, 200);
            
          });
      } catch (error) {
          setTimeout(() => {
              toast.error("Oppss.. The password is invalid or the user does not have a password.");
          }, 200);
      }
    };


    const loginWithJwt = (username,password) => {
      var data = qs.stringify({
        'username': username,
        'password': password
      });
      var config = {
        method: 'post',
        url: POST_LOGIN,
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        return response.data;
      })
      .then(user => {
        setValue(man);
        localStorage.setItem('token', user.access_token);

        const requestOptions = { method: 'GET', headers: authHeader() };
        const userlogin = fetch(GET_USERS_ME, requestOptions).then(handleResponse).then(data => {
          localStorage.setItem('Name', data&&data.full_name?data.full_name:data.email);
          localStorage.setItem('uuid', data.id);
          if(data.is_superuser){
            localStorage.setItem('isSupperUser', data.is_superuser);
          }else{
            localStorage.removeItem('isSupperUser');
          }
          window.location.href = `${process.env.PUBLIC_URL}/dashboard/default/`
        })
        return user;
      })
      .catch(function (error) {
        console.log(error);
        showToaster('dangerToast', error.response.data.detail);
      });
    }

    return (
        <Container fluid={true} className="p-0">
        <Row>
        <Col xs="12">     
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="index.html">
                  <img className="img-fluid for-light w-25" src={require("../assets/images/logo/overdo-logo.svg")} alt=""/>
                  <img className="img-fluid for-dark w-25" src={require("../assets/images/logo/overdo-logo.svg")} alt=""/>
                </a>
              </div>
              <div className="login-main login-tab"> 
                <TabContent activeTab={selected} className="content-login">
                  <TabPane  className="fade show" tabId={"jwt"}>
                    <Form className="theme-form">
                      <h4>{"Sign In"}</h4>
                      <p>{"Enter your username & password to login"}</p>
                      <FormGroup>
                        <Label className="col-form-label">{UserName}</Label>
                        <Input className="form-control" type="text" required="" onChange={e => setUsername(e.target.value)} defaultValue={username} />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">{Password}</Label>
                        <Input className="form-control" type={togglePassword ?  "text" : "password"} onChange={e => setPassword(e.target.value)} defaultValue={password} required=""/>
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                      </FormGroup>
                      <div className="form-group mb-0">
                        <div className="checkbox ml-3">
                          <Input id="checkbox1" type="checkbox"/>
                          <Label className="text-muted" for="checkbox1">{RememberPassword}</Label>
                        </div><a className="link" href="/forgetPwd">{ForgotPassword}</a>
                        {selected === "firebase" ?
                        <Button color="primary" className="btn-block" disabled={loading ? loading : loading} onClick={(e) => loginAuth(e)}>{loading ? "LOADING..." : SignIn }</Button>
                        :
                        <Button color="primary" className="btn-block" onClick={() => loginWithJwt(username,password)}>{SignIn}</Button>
                        }
                      </div>
                      <h6 className="text-muted mt-4 or">{"Or Sign in with"}</h6>
                      <div className="social mt-4">
                        <div className="btn-showcase">
                          <Button color="light" onClick={googleAuth} >
                            <i className="fa fa-google txt-googleplus"></i>
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </TabPane>
                  <TabPane  className="fade show" tabId="auth0">
                    <div className="auth-content">
                        <img src={require("../assets/images/auth-img.svg")} alt="" />
                        <h4>{"Welcome to login with Auth0"}</h4>
                        <p>{"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"}</p>
                        <Button color="info" onClick={loginWithRedirect}>{AUTH0}</Button> 
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Col>
        </Row>
        </Container>
    );
}

export default withRouter(Logins);