import {call,put,takeLatest} from "redux-saga/effects";
import {fetchTaskApi, fetchPendingRequestApi} from '../../api'
import {WATCH_TASK_LIST} from '../actionTypes'
import {getAllTask} from './action'

function* fetchTaskAsyn() {
    const taskList = yield call(fetchPendingRequestApi);
    yield put(getAllTask(taskList.data));
}

export function* watcherTaskApp() {
    yield takeLatest(WATCH_TASK_LIST,fetchTaskAsyn)
}