// Config toast
import { toast } from 'react-toastify';
toast.configure();

export const showToaster = (toastType, toastText) => {
    switch (toastType) {
        case 'successToast':
            toast.success(toastText, {
                position: toast.POSITION.TOP_CENTER
            });
        break;
        case 'infoToast':
            toast.info(toastText, {
                position: toast.POSITION.TOP_CENTER
            });
        break;
        case 'warningToast':
            toast.warn(toastText, {
                position: toast.POSITION.TOP_CENTER
            });
        break;
        case 'dangerToast':
            toast.error(toastText, {
                position: toast.POSITION.TOP_CENTER
            });
        break;
        default:
        break;
    }
}