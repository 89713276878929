import React, { Fragment, useState,useEffect } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, Media } from 'reactstrap'
import {Email,MarekjecnoMailId,BOD,DDMMYY,Designer,ContactUs,ContactUsNumber,LocationDetails,JOHANDIO,UserProfileDesc1,UserProfileDesc2,UserProfileDesc3,Comment,MarkJecno,Like,Follower,Following,Location} from '../../constant'
import { GET_USERS_ME, GET_USERS_ME_FULL } from '../../@config/constant';
import { authHeader, handleResponse } from '../../services/fack.backend';



const UserProfile = (props) => {
  const url = null
  const readUrl = null
  const [userInfo, setUserInfo] = useState(null)

  
  useEffect(() => {

    const requestOptions = { method: 'GET', headers: authHeader() };
    fetch(GET_USERS_ME_FULL, requestOptions).then(handleResponse).then(data => {
      setUserInfo(data)
    })
    // eslint-disable-next-line 
  },[])
  
  return (
    <Fragment>
      <Breadcrumb parent="Users" title="User Profile" />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <Col sm="12">
              <Card className="card hovercard text-center">
                <div className="info">
                  <Row>
                    <Col sm="12" lg="12" className="text-left">
                      <h2>{userInfo&&userInfo.user_info.full_name?userInfo.user_info.full_name:userInfo&&userInfo.user_info.email}</h2>
                    </Col>
                    <Col sm="12" lg="12" className="">
                      <Row >
                        <Col md="6">
                          <div className="ttl-info text-left">
                            <h6><i className="fa fa-envelope mr-2"></i> {Email}</h6><span>{userInfo&&userInfo.user_info.email?userInfo.user_info.email:""}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr/>
                  {userInfo&&userInfo.company_info?
                  <>
                    <Row>
                      <Col sm="12" lg="12" className="text-left">
                        <h2>{userInfo&&userInfo.company_info?userInfo.company_info.name:"--"} Company</h2>
                      </Col>
                      <Col sm="12" lg="12" className="">
                        <Row >
                          <Col md="12">
                            <div className="ttl-info text-left">
                              <h6>{"Company ID: "}</h6><span>{userInfo&&userInfo.company_info?userInfo.company_info.id:"--"}</span>
                            </div>
                          </Col>
                          <Col md="12 pt-2">
                            <div className="ttl-info text-left">
                              <h6>{"Company Name: "}</h6><span>{userInfo&&userInfo.company_info?userInfo.company_info.name:"--"}</span>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>:""}
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default UserProfile;