import React, { useState, Fragment } from 'react';
import { useForm } from 'react-hook-form'
import { Col, Button, Form, Label, Input, FormGroup } from 'reactstrap'
import { NationalID, Date, BGCID, Subject, SupportNeeded, Submit } from '../../../constant'
import { createSupportTicketApi } from '../../../api';
import { showToaster } from '../../../@core/bootstrapNotify';
import DatePicker from "react-datepicker";
import { Typeahead } from 'react-bootstrap-typeahead';


const GenerateSupportTicketForm = () => {

  const { register, handleSubmit, errors } = useForm();
  const [validateClass, setValidateClass] = useState(false);
  const [nationalIds, setNationalIds] = useState(null);
  const [bgcIds, setbgcIds] = useState(null);
  const [subject, setSubject] = useState(null);
  const [support_needed, setSupportNeeded] = useState(null);
  const [disableNationalIds, setDisableNationalIds] = useState(false);
  const [disableBgcIds, setDisableBgcIds] = useState(false);


  const onSubmit = (e, data) => {
    let _data = {
      "national_ids": nationalIds,
      "bgc_ids": bgcIds,
      "subject": subject,
      "support_needed": support_needed
    }
    console.log(_data)
    createSupportTicketApi(_data).then(res => {
      showToaster('successToast', "We will check your request")
      setNationalIds(null)
      setbgcIds(null)
      setSubject("")
      setDisableNationalIds(false)
      setDisableBgcIds(false)
      setSupportNeeded("")
    }).catch(err => {
      showToaster('dangerToast', "Sorry. request again please!")
    })
  };
  const changeValuesNationalIds = (selectedOptions) => {
    if (selectedOptions.length > 0) {
      setDisableBgcIds(true)
    } else {
      setDisableBgcIds(false)
    }
    const selectedNationalIds = selectedOptions.map(v => v.label)
    setNationalIds(selectedNationalIds)
  }
  const changeValuesBgcIds = (selectedOptions) => {
    if (selectedOptions.length > 0) {
      setDisableNationalIds(true)
    } else {
      setDisableNationalIds(false)
    }
    const selected_values = selectedOptions.map(v => v.label)
    setbgcIds(selected_values)
  }

  return (
    <Fragment>
      <Form className={`needs-validation tooltip-validation ${validateClass ? 'validateClass' : ''}`} noValidate="" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <Col md="6 mb-3" disabled>
            <Label>{NationalID}</Label>
            <Typeahead
              id="nationalId-typeahead"
              name="nationalIDs"
              onChange={changeValuesNationalIds}
              allowNew
              multiple={true}
              disabled={disableNationalIds}
              newSelectionPrefix="Add National ID "
              options={[]}
              placeholder="Type National ID..."
              innerRef={register({ required: true })}
            />
          </Col>
          <Col md="6 mb-3">
            <Label>{BGCID}</Label>
            <Typeahead
              id="bgcId-typeahead"
              name="bgcId"
              onChange={changeValuesBgcIds}
              allowNew
              multiple={true}
              disabled={disableBgcIds}
              newSelectionPrefix="Add Background Checks ID "
              options={[]}
              placeholder="Type BGC ID..."
              innerRef={register({ required: true })}
            />
          </Col>
        </div>
        <div className="form-row">
          <Col md="12 mb-3">
            <Label>{Subject}</Label>
            <Input className="form-control" onChange={(e) => (setSubject(e.target.value))} value={subject} name="subject" type="text" placeholder={Subject} innerRef={register({ required: true })} />
          </Col>
        </div>
        <div className="form-row">
          <Col md="12 mb-3">
            <Label>{SupportNeeded}</Label>
            <textarea
              className="form-control"
              rows="5"
              placeholder={SupportNeeded}
              required
              onChange={(e) => (setSupportNeeded(e.target.value))} value={support_needed}
            ></textarea>
          </Col>
        </div>
        <Button color="primary" type="submit" onClick={() => setValidateClass(!validateClass)}>{Submit}</Button>
      </Form>
    </Fragment>
  );
};

export default GenerateSupportTicketForm;