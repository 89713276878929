import React, { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import { Container, Row, Col, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, ModalFooter, Button } from 'reactstrap'
import { Bar } from 'react-chartjs-2';
import { PlusCircle } from 'react-feather';
import { Notification, Daily, Weekly, Monthly, Pending, Failed, Passed, Close, GenerateNotification, Subject, Date, ServiceResumeDate } from '../../constant'
import { Typeahead } from 'react-bootstrap-typeahead';
import { barChartData, barChartOptions } from '../dashboard/chartsData/chartData'
import { useState } from 'react';
import { showToaster } from '../../@core/bootstrapNotify';
import DatePicker from "react-datepicker";
import { fetchCompanyDataApi, fetchNotificationDataApi, createNotificationApi } from '../../api';
import { convertUTCDateToLocalDate } from '../../@core/convertDateTimeToLocal';
import { authHeader, handleResponse } from '../../services/fack.backend';
import { GET_COMPANIES } from '../../@config/constant';


const Statistic = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const isSupperUser = localStorage.getItem('isSupperUser') ? true : false

  const [isLoader, setIsLoader] = useState(true)
  const [company, setCompany] = useState("")
  const [country, setCountry] = useState(null)
  const [service, setService] = useState(null)
  const [startDate, setstartDate] = useState(null)
  const [subject, setSubject] = useState(null)
  const [content, setContent] = useState(null)
  const [notification, setNotification] = useState([])
  const [reloadNotification, setReloadNotificataion] = useState([])
  const [options, setOptions] = useState([])
  const [isMultipleSelect, setIsMultipleSelect] = useState(true)
  const [addNoti, setAddNoti] = useState(false)

  const addToggle = () => { setAddNoti(!addNoti) }

  const handleSelectComany = data => {
    let companies = ""
    data.map((idx) => {
      companies += `${idx.name}, `
    })
    setCompany(companies)
  }

  const handleSelectCountry = data => {
    let countries = ""
    data.map((idx) => {
      countries += `${idx.name}, `
    })
    setCountry(countries)
  }



  const handleSelectService = data => {
    let services = ""
    data.map((idx) => {
      services += `${idx.name}, `
    })
    setService(services)
  }

  const handleChangeDate = date => {
    setstartDate(date)
  }

  const addNotification = (data) => {
    const _data = {
      "content": content,
      "subject": subject,
      "company": company,
      "service_resume_date": startDate,
      "service_effected": service,
      "country_effected": country
    }
    console.log(_data)
    setAddNoti(false)
    createNotificationApi(_data, company).then(res => {
      showToaster('successToast', "Your has been notification to people")
      setReloadNotificataion(!reloadNotification)
    }).catch(ee => {
      showToaster('dangerToast', "Notification send failled")
    })
  }

  const serviecs = [
    { "name": "All Services" },
    { "name": "Policia Nacional" },
    { "name": "INTRANT" },
    { "name": "Judicial Penal" },
    { "name": "Judicial Civil" },
    { "name": "Migracion" },
    { "name": "JCE" }
  ]

  const countries = [
    { "name": "DO" },
    { "name": "CL" },
    { "name": "PR" },
    { "name": "EC" }
  ]

  useEffect(() => {

    setIsLoader(true)
    // fetchCompanyDataApi().then(res => {
    //   setOptions(res.data)
    // })
    const requestOptions = { method: 'GET', headers: authHeader() };
    fetch(GET_COMPANIES, requestOptions).then(handleResponse).then(data => {
      setOptions(data)
    })

    fetchNotificationDataApi().then(res => {
      setNotification(res.data.items)
    })
    setIsLoader(false)
    // eslint-disable-next-line 
  }, [reloadNotification])

  const modalStyle = {
    maxHeight: "calc(100vh - 210px)",
    overflowY: "auto",
  }

  const overlayLayer = {
    paddingTop: "30px",
    position: "absolute",
  }

  const justifyContentCenter = {
    justifyContent: "center"
  }

  const OpacityCss = {
    opacity: "0.1",
    justifyContent: "center"
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update pt-5">
          <Col xl="4 xl-50" className="notification box-col-6 h-100">
            <Card>
              <CardHeader className="card-no-border">
                <div className="header-top">
                  <h5 className="m-0">{Notification}</h5>
                  <div className="card-header-right-icon">
                    {
                      !isSupperUser ? '' :
                        <>
                          <span className="pull-right" onClick={addToggle}><PlusCircle /></span>
                          <Modal size="lg" backdrop={'static'} isOpen={addNoti} toggle={addToggle}>
                            <ModalHeader toggle={addToggle}>
                              {GenerateNotification}
                            </ModalHeader>
                            <ModalBody style={modalStyle}>
                              <Form>
                                <div className="form-row">
                                  <Col md="12">
                                    <Label>{"Send To"}</Label>
                                  </Col>
                                  <Col md="12 mb-3">
                                    <Label>{"Company"}</Label>
                                    <div id="prefetch">
                                      <Form className="theme-form">
                                        <FormGroup>
                                          <Typeahead
                                            id="multiple-typeahead"
                                            // defaultInputValue={"Select all"}
                                            clearButton
                                            labelKey={"name"}
                                            data-order={company}
                                            multiple={true}
                                            options={options}
                                            placeholder="-- Select All --"
                                            onChange={(selected) => { handleSelectComany(selected) }}
                                          />
                                        </FormGroup>
                                      </Form>
                                    </div>
                                  </Col>
                                </div>
                                <div className="form-row">
                                  <Col md="12">
                                    <Label>{"Notification Infomation"}</Label>
                                  </Col>
                                  <Col md="12 mb-3">
                                    <Label>{"Country effected"}</Label>
                                    <div id="prefetch">
                                      <Form className="theme-form">
                                        <FormGroup>
                                          <Typeahead
                                            id="multiple-typeahead"
                                            clearButton
                                            labelKey={"name"}
                                            data-order={countries}
                                            multiple
                                            options={countries}
                                            placeholder="Choose the countries..."
                                            onChange={(selected) => { handleSelectCountry(selected) }}
                                          />
                                        </FormGroup>
                                      </Form>
                                    </div>
                                  </Col>
                                  <Col md="12 mb-3">
                                    <Label>{"Service effected"}</Label>
                                    <div id="prefetch">
                                      <Form className="theme-form">
                                        <FormGroup>
                                          <Typeahead
                                            id="multiple-typeahead"
                                            clearButton
                                            labelKey={"name"}
                                            data-order={serviecs}
                                            multiple
                                            options={serviecs}
                                            placeholder="Choose the services..."
                                            onChange={(selected) => { handleSelectService(selected) }}
                                          />
                                        </FormGroup>
                                      </Form>
                                    </div>
                                  </Col>
                                  <Col md="12 mb-3">
                                    <Label>{ServiceResumeDate}</Label>
                                    <DatePicker className="form-control digits" selected={startDate} dateFormat="Pp" showTimeSelect onChange={handleChangeDate} />
                                  </Col>
                                  <Col md="12 mb-3">
                                    <Label>{Subject}</Label>
                                    <Input className="form-control" onChange={(e) => { setSubject(e.target.value) }} name="subject" type="text" placeholder={Subject} innerRef={register({ required: true })} />
                                  </Col>
                                </div>
                                <div className="form-row">
                                  <Col md="12 mb-3">
                                    <Label>{"Content"}</Label>
                                    <textarea
                                      className="form-control"
                                      rows="5"
                                      placeholder={"Content"}
                                      onChange={(e) => { setContent(e.target.value) }}
                                      required
                                    ></textarea>
                                  </Col>
                                </div>
                              </Form>
                            </ModalBody>
                            <ModalFooter>
                              <Button color="secondary" onClick={addToggle}>{Close}</Button>
                              <Button color="primary" onClick={addNotification}>{'Create Notification'}</Button>
                            </ModalFooter>
                          </Modal>
                        </>
                    }
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                {isLoader ?
                  <>
                    <Col md="3">
                      <div className="loader-box">
                        <div className="loader-39"></div>
                      </div>
                    </Col>
                  </>
                  :
                  <>
                    {notification.length < 0 ? '' : notification.map((noti) => (<>
                      <div className="media">
                        <div className="media-body" style={{ "border": "1px" }}>
                          <p>{convertUTCDateToLocalDate(noti.noti_date)}</p>
                          <h6>{noti.subject}<span className="dot-notification"></span></h6>
                          <span style={{ whiteSpace: "pre-line" }}>{noti.content}</span>
                          <br />
                          <br />
                          <span style={{ whiteSpace: "pre-line" }}><strong>{"Service resume date"}</strong>: {convertUTCDateToLocalDate(noti.service_resume_date)}</span>
                          <br />
                          <span style={{ whiteSpace: "pre-line" }}><strong>{"Service effected"}</strong>: {noti.service_effected}</span>
                          <br />
                          <span style={{ whiteSpace: "pre-line" }}><strong>{"Country effected"}</strong>: {noti.country_effected}</span>
                        </div>
                      </div>
                      <hr />
                    </>))}
                  </>
                }
              </CardBody>
            </Card>
          </Col>
          <Col xl="8 xl-50" className="dashboard-sec box-col-12">
            <Card className="earning-card">
              <CardBody className="p-0">
                <Row className="m-0" style={justifyContentCenter}>
                  <h5 style={overlayLayer}>{"Released soon"}</h5>
                  <Col xl="12" className="p-0" style={OpacityCss}>
                    <div className="chart-right">
                      <Row className="m-0 p-tb">
                        <Col xl="12" md="12" sm="12" className="col-12 p-0">
                          <div className="inner-top-left">
                            <ul className="d-flex list-unstyled">
                              <li>{Daily}</li>
                              <li className="active">{Weekly}</li>
                              <li>{Monthly}</li>
                            </ul>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <CardBody className="p-0">
                            <div className="current-sale-container">
                              <Bar data={barChartData} options={barChartOptions} width={778} height={350} />
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <Row className="border-top m-0">
                      <Col xl="4" md="4" sm="4">
                        <div className="media p-0">
                          <div className="media-left bg-success"><i className="icofont icofont-checked"></i></div>
                          <div className="media-body">
                            <h6>{Passed}</h6>
                            <p>{"0"}</p>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" md="4" sm="4" className="pr-0">
                        <div className="media p-0">
                          <div className="media-left bg-secondary"><i className="icofont icofont-stop"></i></div>
                          <div className="media-body">
                            <h6>{Failed}</h6>
                            <p>{"0"}</p>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" md="4" sm="4" className="pl-0">
                        <div className="media p-0">
                          <div className="media-left bg-warning"><i className="icofont icofont-pause"></i></div>
                          <div className="media-body">
                            <h6>{Pending}</h6>
                            <p>{"0"}</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default Statistic;