import React, { useState, useCallback, useMemo, useEffect } from 'react';
import differenceBy from 'lodash/differenceBy';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component'
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label } from 'reactstrap';
import { createSupportTicketResolveApi, fetchSupportTicketDataApi } from '../../../api';
import FilterComponent from './FilterComponent';

const SupportTicketList = (props) => {
  const [clickedRow, setClickedRow] = useState(null)
  const [toggleCleared, setToggleCleared] = useState(false);
  const [support_ticket, setSupportTicket] = useState([]);
  const [modalCloseTicket, setModalCloseTicket] = useState(false);
  const [modalShowsTicket, setModalShowsTicket] = useState(false);
  const [feedbackResult, setFeedbackResult] = useState("");
  const [attachmentFile, setAttachmentFile] = useState();
  const toggleModelCloseTicket = () => setModalCloseTicket(!modalCloseTicket);
  const toggleModelShowsTicket = () => setModalShowsTicket(!modalShowsTicket);



  useEffect(() => {

    fetchSupportTicketDataApi({ page: 1, limit: 100 }).then(res => {
      setSupportTicket(res.data)
    })
    // eslint-disable-next-line 
  }, [toggleCleared])



  const tableColumns = [
    {
      name: 'TicketID',
      selector: 'id',
      sortable: false,
      center: true,
    },
    {
      name: 'Subject',
      selector: 'subject',
      sortable: true,
      center: true,
    },
    {
      name: 'Support Needed',
      selector: 'support_needed',
      sortable: true,
      center: true,
    },
    {
      name: 'Status',
      selector: d => d.is_resolve ? "Resolved" : "Open",
      sortable: false,
      center: true,
    },
    {
      name: 'HandlerId',
      selector: 'handler_id',
      sortable: true,
      center: true,
    },
    {
      name: 'CreateAt',
      selector: 'date',
      sortable: true,
      center: true,
    },
  ]

  // ----------------
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  let filteredItems = []

  function filterSupportTicket(item) {
    item.status = !item.is_resolve ? "open" : "resolved"
    return JSON.stringify(item)
      .toLowerCase()
      .indexOf(filterText.toLowerCase()) !== -1
  }

  if (support_ticket.items) {
    filteredItems = support_ticket.items.filter(filterSupportTicket)
  }
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);


  const handleCloseTicket = () => {


    const MAX_SIZE_FILE = 10240000

    if (!!attachmentFile) {
      const fileSize = attachmentFile.size
      if (fileSize > MAX_SIZE_FILE) {
        console.log(fileSize)
        console.log(MAX_SIZE_FILE)
        toast.error("File size is greater than maximum limit")
        return
      }
    }
    if (!feedbackResult |  feedbackResult == "") {
      toast.error("Response content is required")
      return
    }
    const formData = new FormData()
    formData.append("id", clickedRow.id)
    formData.append("feedback_result", feedbackResult)
    formData.append(
      "attachment",
      attachmentFile,
      attachmentFile.name
    )
    createSupportTicketResolveApi(formData).then(res => {
      setToggleCleared(false)
    })
    setToggleCleared(true)
    toggleModelCloseTicket()
    toggleModelShowsTicket()
    toast.success("Ticket Closed")
  };

  const showsDetailTicket = (e) => {
    setModalShowsTicket(!modalShowsTicket)
    setClickedRow(e)
  }

  return (
    <Row>
      <Col sm="12">
        <DataTable
          data={filteredItems.length < 0 ? support_ticket.items : filteredItems}
          columns={tableColumns}
          selectableRowsHighlight={true}
          highlightOnHover={true}
          pointerOnHover={true}
          clearSelectedRows={toggleCleared}
          subHeaderComponent={subHeaderComponent}
          onRowClicked={showsDetailTicket}
          selectableRows={false}
          selectableRowsNoSelectAll
          persistTableHead
          pagination
          subHeader
        />
      </Col>
      <Modal isOpen={modalShowsTicket} toggle={toggleModelShowsTicket} size="lg">
        <ModalHeader toggle={toggleModelShowsTicket}>
          {"Support Ticket Information"}
        </ModalHeader>
        <ModalBody>
          <Col xl="12 xl-50" md="5">
            {clickedRow &&
              <div className="media">
                <div className="media-body">
                  <p><strong>ID:</strong> {clickedRow.id}</p>
                  <p><strong>User ID:</strong> {clickedRow.user_id}</p>
                  <p><strong>National IDs:</strong> {clickedRow.national_ids ? clickedRow.national_ids.join(", ") : ""}</p>
                  <p><strong>BGC IDs:</strong> {clickedRow.bgc_ids ? clickedRow.bgc_ids.join(", ") : ""}</p>
                  <p><strong>Subject:</strong> {clickedRow.subject}</p>
                  <p><strong>Support Needed:</strong> {clickedRow.support_needed}</p>
                  <p><strong>Create At:</strong> {clickedRow.date}</p>
                </div>
              </div>
            }
            {clickedRow && clickedRow.is_resolve ?
              <div className="media">
                <div className="media-body">
                  <p><strong>Feedback:</strong> {clickedRow.feedback_result}</p>
                  <p><strong>Attachments: </strong>{"File"}</p>
                </div>
              </div> :
              <div>
                <Col xl="12 xl-50" md="5">
                  <Label><strong>Response For Ticket</strong></Label>
                  <Input
                    className="form-control"
                    onChange={(e) => setFeedbackResult(e.target.value)}
                    name="response-ticket"
                    type="textarea"
                    rows="7"
                    placeholder="Type something..."
                  />
                </Col>
                <Col xl="12 xl-50" md="5">
                  <Label><strong>Attachment File</strong></Label>
                  <Input type='file' onChange={(e) => setAttachmentFile(e.target.files[0])} name='fileAttachment' />
                  <p className="info-message">Max size: 10MB</p>
                </Col>
              </div>
            }
          </Col>

        </ModalBody>
        <ModalFooter>
          {clickedRow && !clickedRow.is_resolve ?
            <>
              <Button color="secondary" onClick={toggleModelShowsTicket}>Cancel</Button>
              <Button color="primary" onClick={handleCloseTicket}>Resolve</Button>
            </>
            : <Button color="primary" onClick={toggleModelShowsTicket}>OK</Button>
          }

        </ModalFooter>
      </Modal>
    </Row>
  );

};

export default SupportTicketList;