import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card,  CardBody, ListGroup, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { fetchPendingRequestApi } from '../../api';
import {Next, NoPendingRequestFound, Previous} from '../../constant'

const PendingRequest = (props) => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false);
  const [backgroundCheckData, setBackgroundCheckData] = useState([])

  useEffect(() => {
    setShow(false)
    fetchPendingRequestApi().then(response => {
      setBackgroundCheckData(response.data)
      setShow(true)
    })
    .catch((err) => {
      setShow(true)
      console.log("error")
    });
  },[])

  // const tasklist = useSelector(content => content.Taskapp.task);

  return (
    <Fragment>
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update pt-5">
          <Col sm="12" xl="12">
            <Card>
              <CardBody>
                <ListGroup>
                  <Col sm="12" xl="12" lg="12">
                  {
                    !show?<>
                    <Col>
                      <div className="loader-box">
                        <div className="loader-39"></div>
                      </div>
                    </Col>
                    </>:""}
                  {backgroundCheckData.length > 0 ?
                    backgroundCheckData.map((bgc, index) => {
                    return (
                      <Card className="o-hidden" key={index} style={{display: !show?'none':''}}>
                        <CardBody className="p-3">
                          <div className="media static-top-widget">
                            <div className="align-self-right text-right"></div>
                            <div className="media-body">
                              <span className="m-0">{`${index + 1}. ${bgc.person.first_name} ${bgc.person.middle_name} ${bgc.person.last_name}`} / #{bgc.id}</span>
                            </div>
                            <span className="badge bg-warning counter digits">{bgc.status}</span>
                          </div>
                        </CardBody>
                      </Card>
                    )
                    })
                    : 
                    <Card className="o-hidden" style={{display: !show?'none':''}}>
                      <CardBody className="p-3">
                        <div className="media static-top-widget">
                          <div className="align-self-right text-right"></div>
                          <div className="media-body">
                            <span className="m-0">{NoPendingRequestFound}</span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  }
                  {/* <Pagination aria-label="Page navigation example" className="pagination justify-content-center pagination-primary" >
                    <PaginationItem><PaginationLink href="#javascript"><span aria-hidden="true">«</span><span className="sr-only">{Previous}</span></PaginationLink></PaginationItem>
                    <PaginationItem><PaginationLink href="#javascript">{"1"}</PaginationLink></PaginationItem>
                    <PaginationItem><PaginationLink href="#javascript">{"2"}</PaginationLink></PaginationItem>
                    <PaginationItem><PaginationLink href="#javascript">{"3"}</PaginationLink></PaginationItem>
                    <PaginationItem><PaginationLink href="#javascript"><span aria-hidden="true">»</span><span className="sr-only">{Next}</span></PaginationLink></PaginationItem>
                  </Pagination> */}
                  </Col>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default PendingRequest;