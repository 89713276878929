import {call,put, takeLatest} from "redux-saga/effects";
import { fetchNotificationApi } from "../../api";
import { GET_NOTIFICATION_LIST, WATCH_NOTIFICATION_LIST } from "../../redux/actionTypes";

function* fetchNotificationAsyn() {
    const Data = yield call(fetchNotificationApi);
    yield put({type : GET_NOTIFICATION_LIST,notification:Data});
}

export function* watchNotificationList() {
    yield takeLatest(WATCH_NOTIFICATION_LIST,fetchNotificationAsyn)
}