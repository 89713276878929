import configDB from '../../../data/customizer/config'
const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || configDB.data.color.secondary_color;
export const apexBarChart = {
    series: [{
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  }],
  options: {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    colors:[primary],
    xaxis: {
      categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
        'United States', 'China', 'Germany'
      ],
    }
  },


};

export const barChartData = {
  labels: ['12/12/2022', '13/12/2022', '14/12/2022', '15/12/2022', '16/12/2022', '17/12/2022', '18/12/2022'],
  datasets: [
        {
          label: 'passed',
          lagend: 'x',
          data: [0, 0, 0, 0, 0, 0, 0],
          borderColor: '#51bb25',
          backgroundColor: "#51bb25",
          highlightFill: "#51bb25",
          highlightStroke: '#51bb25',
          borderWidth: 2
      },
      {
          label: 'failed',
          lagend: 'y',
          data: [0, 0, 0, 0, 0, 0, 0],
          borderColor: '#f8d62b',
          backgroundColor: "#f8d62b",
          highlightFill: "#f8d62b",
          highlightStroke: '#f8d62b',
          borderWidth: 2
      },
      {
          label: 'pending',
          lagend: 'z',
          data: [0, 0, 0, 0, 0, 0, 0],
          borderColor: "#f73164",
          backgroundColor: "#f73164",
          highlightFill: "#f73164",
          highlightStroke: "#f73164",
          borderWidth: 2
      }
  ],
  plugins: {
      datalabels: {
          display: false,
          color: 'white'
      }
  }
}

export const barChartOptions = {
  maintainAspectRatio: true,
  legend: {
      display: false,
  },
  plugins: {
      datalabels: {
          display: false,
      }
  }
}