import { Clock, Calendar, Bell, BarChart2, Bookmark, Folder, Home} from 'react-feather'
export const MENUITEMS = [
    {
        Items:[
            { path: `${process.env.PUBLIC_URL}/dashboard/default`, icon: Home, title: 'Dashboard', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/pending-request`, icon: Clock, title: 'Pending Request', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/bgc-by-date`, icon: Calendar, title: 'BGC By Date', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/generate-bgc-report`, icon: Folder, title: 'Generate BGC Report', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/generate-support-ticket`, icon: Bookmark, title: 'Generate Support Ticket', type: 'link' }
        ]
    }
]